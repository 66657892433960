import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Stage, Layer, Rect, Text, Arrow, Transformer } from 'react-konva';

const IDEF0Diagram = ({ diagramData, onTextChange, onArrowChange, onTextPositionChange }) => {
  const [mainBlockPos, setMainBlockPos] = useState({ x: 400, y: 300 });
  const [textOffsets, setTextOffsets] = useState({});
  const [editingTextId, setEditingTextId] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [selectedNode, setSelectedNode] = useState(null);

  const textInputRef = useRef(null);
  const stageRef = useRef(null);
  const transformerRef = useRef(null);
  const nodesRef = useRef({});

  const mainBlockConfig = {
    width: 300,
    height: 150,
    fill: '#ffffff',
    stroke: '#4CAF50',
    strokeWidth: 2,
    cornerRadius: 5,
  };

  useEffect(() => {
    if (transformerRef.current && selectedNode) {
      transformerRef.current.nodes([selectedNode]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedNode]);

  const calculateBaseTextPosition = (arrowType) => {
    const textHeight = 20;
    switch (arrowType) {
      case 'inputs':
        return { x: -140, y: mainBlockConfig.height / 2 - textHeight / 2 - 30 };
      case 'outputs':
        return { x: mainBlockConfig.width + 40, y: mainBlockConfig.height / 2 - textHeight / 2 - 30 };
      case 'controls':
        return { x: mainBlockConfig.width / 2 - 60, y: -70 };
      case 'mechanisms':
        return { x: mainBlockConfig.width / 2 - 60, y: mainBlockConfig.height + 50 };
      default:
        return { x: 0, y: 0 };
    }
  };

  const getTextPosition = (arrow) => {
    const basePos = calculateBaseTextPosition(arrow.type);
    const offset = textOffsets[arrow.id] || { x: 0, y: 0 };
    return {
      x: mainBlockPos.x + basePos.x + offset.x,
      y: mainBlockPos.y + basePos.y + offset.y,
    };
  };

  const handleElementDblClick = (element, textNode) => {
    const stage = textNode.getStage();
    const textPosition = textNode.getAbsolutePosition();
    const stageBox = stage.container().getBoundingClientRect();

    const inputX = textPosition.x + stageBox.left;
    const inputY = textPosition.y + stageBox.top;

    setEditingTextId(element.id);
    setTextValue(element.label || element.text);

    if (element.type) {
      const basePos = calculateBaseTextPosition(element.type);
      setTextOffsets((prev) => ({
        ...prev,
        [element.id]: {
          x: textPosition.x - mainBlockPos.x - basePos.x,
          y: textPosition.y - mainBlockPos.y - basePos.y,
        },
      }));
    }

    requestAnimationFrame(() => {
      if (textInputRef.current) {
        textInputRef.current.style.transform = `translate(${inputX}px, ${inputY}px)`;
        textInputRef.current.focus();
        textInputRef.current.select();
      }
    });
  };

  const handleTextChange = () => {
    if (onTextChange && editingTextId) {
      const elementType = diagramData.arrows.some((a) => a.id === editingTextId)
        ? 'arrow'
        : 'text';
      onTextChange(editingTextId, textValue, elementType);
    }
    setEditingTextId(null);
  };

  const handleArrowPointsChange = (arrowId, newPoints) => {
    const arrow = diagramData.arrows.find(a => a.id === arrowId);
    if (!arrow) return;
  
    let constrainedPoints;
    const mainX = mainBlockPos.x;
    const mainY = mainBlockPos.y;
    const mainWidth = mainBlockConfig.width;
    const mainHeight = mainBlockConfig.height;
  
    switch (arrow.type) {
      case 'inputs':
        constrainedPoints = [
          mainX - 100,
          Math.min(Math.max(newPoints[1], mainY), mainY + mainHeight), // Ограничение по Y
          mainX,
          Math.min(Math.max(newPoints[3], mainY), mainY + mainHeight)  // Ограничение по Y
        ];
        break;
      case 'outputs':
        constrainedPoints = [
          mainX + mainWidth,
          Math.min(Math.max(newPoints[1], mainY), mainY + mainHeight), // Ограничение по Y
          mainX + mainWidth + 100,
          Math.min(Math.max(newPoints[3], mainY), mainY + mainHeight)  // Ограничение по Y
        ];
        break;
      case 'controls':
        constrainedPoints = [
          Math.min(Math.max(newPoints[0], mainX), mainX + mainWidth), // Ограничение по X
          mainY - 50,
          Math.min(Math.max(newPoints[2], mainX), mainX + mainWidth), // Ограничение по X
          mainY
        ];
        break;
      case 'mechanisms':
        constrainedPoints = [
          Math.min(Math.max(newPoints[0], mainX), mainX + mainWidth), // Ограничение по X
          mainY + mainHeight + 50,
          Math.min(Math.max(newPoints[2], mainX), mainX + mainWidth), // Ограничение по X
          mainY + mainHeight
        ];
        break;
      default:
        constrainedPoints = newPoints;
    }
  
    const basePos = calculateBaseTextPosition(arrow.type);
    const newOffset = {
      x: arrow.type === 'controls' || arrow.type === 'mechanisms' 
         ? (constrainedPoints[0] - mainX - basePos.x)
         : 0,
      y: arrow.type === 'inputs' || arrow.type === 'outputs'
         ? (constrainedPoints[1] - mainY - basePos.y)
         : 0
    };
  
    setTextOffsets(prev => ({
      ...prev,
      [arrowId]: newOffset
    }));
  
    if (onArrowChange) onArrowChange(arrowId, constrainedPoints);
  };

  const handleElementDragMove = (element, e) => {
    const newX = e.target.x();
    const newY = e.target.y();

    if (element.type) {
      const basePos = calculateBaseTextPosition(element.type);
      setTextOffsets((prev) => ({
        ...prev,
        [element.id]: {
          x: newX - mainBlockPos.x - basePos.x,
          y: newY - mainBlockPos.y - basePos.y,
        },
      }));
    } else {
      onTextPositionChange(element.id, newX, newY);
    }
  };

  const processedArrows = useMemo(() => {
    return diagramData?.arrows?.map((arrow) => {
      const basePoints = {
        inputs: [
          mainBlockPos.x - 100,
          mainBlockPos.y + mainBlockConfig.height / 2,
          mainBlockPos.x,
          mainBlockPos.y + mainBlockConfig.height / 2
        ],
        outputs: [
          mainBlockPos.x + mainBlockConfig.width,
          mainBlockPos.y + mainBlockConfig.height / 2,
          mainBlockPos.x + mainBlockConfig.width + 100,
          mainBlockPos.y + mainBlockConfig.height / 2
        ],
        controls: [
          mainBlockPos.x + mainBlockConfig.width / 2,
          mainBlockPos.y - 50,
          mainBlockPos.x + mainBlockConfig.width / 2,
          mainBlockPos.y
        ],
        mechanisms: [
          mainBlockPos.x + mainBlockConfig.width / 2,
          mainBlockPos.y + mainBlockConfig.height + 50,
          mainBlockPos.x + mainBlockConfig.width / 2,
          mainBlockPos.y + mainBlockConfig.height
        ]
      }[arrow.type];

      return {
        ...arrow,
        points: arrow.points || basePoints,
        textPos: getTextPosition(arrow)
      };
    });
  }, [mainBlockPos, diagramData?.arrows, textOffsets]);

  return (
    <div>
      <Stage
        width={800}
        height={600}
        ref={stageRef}
        onClick={(e) => {
          if (e.target === e.target.getStage()) {
            setSelectedNode(null);
          }
        }}
      >
        <Layer>
          <Rect
            {...mainBlockConfig}
            x={mainBlockPos.x}
            y={mainBlockPos.y}
            draggable
            onDragMove={(e) => {
              const newX = e.target.x();
              const newY = e.target.y();
              setMainBlockPos({ x: newX, y: newY });
              
              // Обновление всех стрелок при перемещении блока
              diagramData.arrows.forEach(arrow => {
                const basePoints = {
                  inputs: [
                    newX - 100,
                    newY + mainBlockConfig.height / 2,
                    newX,
                    newY + mainBlockConfig.height / 2
                  ],
                  outputs: [
                    newX + mainBlockConfig.width,
                    newY + mainBlockConfig.height / 2,
                    newX + mainBlockConfig.width + 100,
                    newY + mainBlockConfig.height / 2
                  ],
                  controls: [
                    newX + mainBlockConfig.width / 2,
                    newY - 50,
                    newX + mainBlockConfig.width / 2,
                    newY
                  ],
                  mechanisms: [
                    newX + mainBlockConfig.width / 2,
                    newY + mainBlockConfig.height + 50,
                    newX + mainBlockConfig.width / 2,
                    newY + mainBlockConfig.height
                  ]
                }[arrow.type];

                handleArrowPointsChange(arrow.id, basePoints);
              });
            }}
          />

          <Text
            x={mainBlockPos.x + 20}
            y={mainBlockPos.y + 20}
            width={mainBlockConfig.width - 40}
            height={mainBlockConfig.height - 40}
            text={diagramData?.mainProcess?.label || 'Основной процесс'}
            fontSize={16}
            align="center"
            verticalAlign="middle"
          />

          {processedArrows?.map((arrow) => (
            <React.Fragment key={arrow.id}>
              <Arrow
                points={arrow.points}
                stroke="#000"
                strokeWidth={2}
                pointerLength={10}
                ref={(ref) => (nodesRef.current[`arrow-${arrow.id}`] = ref)}
                onClick={(e) => {
                  e.cancelBubble = true;
                  setSelectedNode(nodesRef.current[`arrow-${arrow.id}`]);
                }}
                draggable
                onDragMove={(e) => {
                  const arrowType = arrow.type;
                  let newPoints;

                  if (arrowType === 'inputs' || arrowType === 'outputs') {
                    newPoints = [
                      arrow.points[0],
                      e.target.y(),
                      arrow.points[2],
                      e.target.y()
                    ];
                  } else {
                    newPoints = [
                      e.target.x(),
                      arrow.points[1],
                      e.target.x(),
                      arrow.points[3]
                    ];
                  }

                  handleArrowPointsChange(arrow.id, newPoints);
                }}
              />
              <Text
                x={arrow.textPos.x}
                y={arrow.textPos.y}
                text={arrow.label}
                fontSize={12}
                fill="#000"
                padding={5}
                draggable
                ref={(ref) => (nodesRef.current[arrow.id] = ref)}
                onClick={(e) => {
                  e.cancelBubble = true;
                  setSelectedNode(nodesRef.current[arrow.id]);
                }}
                onDragMove={(e) => handleElementDragMove(arrow, e)}
                onDblClick={(e) => handleElementDblClick(arrow, e.target)}
              />
            </React.Fragment>
          ))}

          {diagramData?.texts?.map((text) => (
            <Text
              key={text.id}
              x={text.x}
              y={text.y}
              text={text.text}
              fontSize={14}
              fill="#000"
              padding={5}
              draggable
              ref={(ref) => (nodesRef.current[text.id] = ref)}
              onClick={(e) => {
                e.cancelBubble = true;
                setSelectedNode(nodesRef.current[text.id]);
              }}
              onDragMove={(e) => handleElementDragMove(text, e)}
              onDblClick={(e) => handleElementDblClick(text, e.target)}
            />
          ))}

          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => ({
              ...newBox,
              width: oldBox.width,
              height: oldBox.height
            })}
            enabledAnchors={['middle-left', 'middle-right', 'top-center', 'bottom-center']}
            rotateEnabled={false}
          />
        </Layer>
      </Stage>

      {editingTextId && (
        <input
          ref={textInputRef}
          value={textValue}
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '150px',
            fontSize: '12px',
            padding: '4px',
            border: '2px solid #3498db',
            borderRadius: '4px',
            background: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            zIndex: 1000,
            transform: 'translate(0, 0)',
          }}
          onChange={(e) => setTextValue(e.target.value)}
          onBlur={handleTextChange}
          onKeyPress={(e) => e.key === 'Enter' && handleTextChange()}
        />
      )}
    </div>
  );
};

export default IDEF0Diagram;