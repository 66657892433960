import React, { useState } from 'react';
import IDEF0Diagram from '../components/IDEF0Diagram';

export default function CatalogPage() {
  const [description, setDescription] = useState('');
  const [diagramData, setDiagramData] = useState({
    mainProcess: { label: 'Основной процесс' },
    arrows: [],
    texts: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTextChange = (elementId, newText, elementType) => {
    setDiagramData((prev) => {
      if (elementType === 'text') {
        return {
          ...prev,
          texts: prev.texts.map((text) =>
            text.id === elementId ? { ...text, text: newText } : text
          ),
        };
      }
      return {
        ...prev,
        arrows: prev.arrows.map((arrow) =>
          arrow.id === elementId ? { ...arrow, label: newText } : arrow
        ),
      };
    });
  };

  const handleTextPositionChange = (textId, x, y) => {
    setDiagramData((prev) => ({
      ...prev,
      texts: prev.texts.map((text) =>
        text.id === textId ? { ...text, x, y } : text
      ),
    }));
  };

  const handleArrowPointsChange = (arrowId, newPoints) => {
    setDiagramData((prev) => ({
      ...prev,
      arrows: prev.arrows.map((arrow) =>
        arrow.id === arrowId ? { ...arrow, points: newPoints } : arrow
      ),
    }));
  };

  const addArrow = (type) => {
    const newArrow = {
      id: `arrow-${Date.now()}`,
      type,
      label: 'Новая стрелка',
      points: null, // Точки будут рассчитаны автоматически
    };

    setDiagramData((prev) => ({
      ...prev,
      arrows: [...prev.arrows, newArrow],
    }));
  };

  const addText = () => {
    const newText = {
      id: `text-${Date.now()}`,
      x: 100,
      y: 100,
      text: 'Новый текст',
    };

    setDiagramData((prev) => ({
      ...prev,
      texts: [...prev.texts, newText],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setDiagramData({
      mainProcess: { label: 'Основной процесс' },
      arrows: [],
      texts: [],
    });

    try {
      const response = await fetch('/api/idef0/generate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ description: description.trim() }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || `Ошибка сервера: ${response.status}`);
      }

      const validArrows = data.arrows
        .filter((arrow) =>
          ['inputs', 'outputs', 'controls', 'mechanisms'].includes(arrow?.type) &&
          typeof arrow.label === 'string'
        )
        .map((arrow, index) => ({
          ...arrow,
          id: `arrow-${index}`,
          label: arrow.label.trim(),
        }));

      setDiagramData({
        mainProcess: { label: data.mainProcess.label.trim() },
        arrows: validArrows,
        texts: [],
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ 
      padding: '20px',
      display: 'flex',
      gap: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
      minHeight: '100vh'
    }}>
      <div style={{ 
        width: '200px',
        background: '#f8f9fa',
        padding: '15px',
        borderRadius: '14px',
        boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
      }}>
        <h3 style={{ marginTop: 0 }}>Инструменты</h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <button onClick={() => addArrow('inputs')} style={buttonStyle}>
            ➕ Вход
          </button>
          <button onClick={() => addArrow('outputs')} style={buttonStyle}>
            ➕ Выход
          </button>
          <button onClick={() => addArrow('controls')} style={buttonStyle}>
            ➕ Управление
          </button>
          <button onClick={() => addArrow('mechanisms')} style={buttonStyle}>
            ➕ Механизм
          </button>
          <button onClick={addText} style={buttonStyle}>
            📝 Добавить текст
          </button>
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <h1 style={{ textAlign: 'center', color: '#2c3e50', marginBottom: '30px' }}>
          🗂️ Генератор IDEF0 диаграмм
        </h1>

        <form onSubmit={handleSubmit} style={{ 
          maxWidth: '800px',
          margin: '0 auto 30px',
          background: '#f8f9fa',
          padding: '25px',
          borderRadius: '14px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}>
          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', marginBottom: '12px', fontWeight: '600' }}>
              📝 Описание бизнес-процесса:
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Пример: Процесс обработки заказов клиентов..."
              style={textareaStyle}
              minLength={20}
              required
            />
          </div>
          
          <button type="submit" disabled={loading} style={submitButtonStyle}>
            {loading ? '⏳ Генерация...' : '🚀 Создать диаграмму'}
          </button>
        </form>

        {error && (
          <div style={errorStyle}>
            <span style={{ fontSize: '24px' }}>⚠️</span>
            <div>
              <h3 style={{ margin: '0 0 8px' }}>Ошибка:</h3>
              <p style={{ margin: 0 }}>{error}</p>
            </div>
          </div>
        )}

        {diagramData && (
          <div style={diagramContainerStyle}>
            <div style={diagramHeaderStyle}>
              <span>📊</span>
              {diagramData.mainProcess.label}
            </div>
            <div style={{ padding: '25px' }}>
              <IDEF0Diagram 
                diagramData={diagramData} 
                onTextChange={handleTextChange}
                onTextPositionChange={handleTextPositionChange}
                onArrowChange={handleArrowPointsChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const buttonStyle = {
  padding: '10px',
  background: '#3498db',
  color: 'white',
  border: 'none',
  borderRadius: '6px',
  cursor: 'pointer',
  transition: 'background 0.3s',
  ':hover': {
    background: '#2980b9'
  }
};

const textareaStyle = {
  width: '100%',
  height: '140px',
  padding: '15px',
  border: '2px solid #dfe6e9',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '1.6',
  resize: 'vertical'
};

const submitButtonStyle = {
  width: '100%',
  padding: '16px 28px',
  background: '#3498db',
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  fontSize: '17px',
  fontWeight: '600',
  cursor: 'pointer',
  transition: 'all 0.3s ease'
};

const errorStyle = {
  background: '#ffeef0',
  color: '#ff5252',
  padding: '18px',
  borderRadius: '10px',
  margin: '25px auto',
  maxWidth: '800px',
  border: '2px solid #ffd6d9',
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
};

const diagramContainerStyle = {
  margin: '40px auto',
  maxWidth: '1000px',
  boxShadow: '0 6px 18px rgba(0,0,0,0.08)',
  borderRadius: '16px',
  overflow: 'hidden',
  background: 'white'
};

const diagramHeaderStyle = {
  padding: '20px 32px',
  background: '#2c3e50',
  color: 'white',
  fontSize: '1.4rem',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
};