import React from 'react';
import './TutorPlus.css';

const TutorPlus = () => {
  return (
    <div className="tutor-plus-container">
      <h1 className="title">Тьютор+</h1>
      <p className="subtitle">
        Умный тьютор для помощи в решении домашних заданий и научных работ.
      </p>
      
      <div className="info-section">
        <p>
          Наш сервис предоставляет быстрый доступ к квалифицированным экспертам, которые помогут разобраться с домашними заданиями, эссе, исследованиями и сложными задачами.
        </p>
        <ul className="features-list">
          <li>📚 Поддержка 24/7 — задавайте вопросы в любое время.</li>
          <li>👨‍🏫 Персонализированные рекомендации от профессионалов.</li>
          <li>💬 Удобный формат общения прямо в Telegram.</li>
          <li>📎 Возможность прикреплять файлы для анализа и разборов.</li>
        </ul>
      </div>

      <div className="cta-section">
        <p>Перейдите в Telegram и получите помощь прямо сейчас:</p>
        <a
          href="https://t.me/diplortutor"
          target="_blank"
          rel="noopener noreferrer"
          className="telegram-button"
        >
        </a>
      </div>
    </div>
  );
};

export default TutorPlus;
